var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status
    ? _c(
        "span",
        { staticClass: "status", class: _vm.size, style: _vm.cssStyles },
        [_vm._v(" " + _vm._s(_vm.status.label) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }